import { useMsal } from '@azure/msal-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { User } from '../../pages/User';
import { darkTheme as theme } from '../../configs/theme';
import React from 'react';
import { PowerSettingsNew } from '@mui/icons-material';
import { useDelay } from '../../libs/Delay/Delay';

export function SignOutButton() {
  const { instance } = useMsal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const autoCloseInterval = 2000;

  useDelay(open,()=>{setAnchorEl(null);},autoCloseInterval);
  /* useEffect(() => {
    if (open) {
      setTimeout(() => {
        setAnchorEl(null);
        console.log('auto login popup close');
      }, autoCloseInterval);
    }
  }, [open]); */

  const handleLogout = (logoutType: string) => {
    setAnchorEl(null);

    if (logoutType === 'popup') {
      const environment = process.env.NODE_ENV === "production" ? "PROD" : "DEV";
      instance
        .logoutPopup({
          mainWindowRedirectUri: environment === "PROD" ? process.env.REACT_APP_PROD_BASEPATH : process.env.REACT_APP_DEV_BASEPATH, // updated to env file base path '/',
        })
        .catch((error) => { console.log(error); });
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect();
    }
  };

  return (
    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} color="inherit" sx={{ color: theme.palette.primary.light, '&:hover': { zoom: '1.0' },width:'42px',height:'40px' }}>
        <AccountCircle sx={{width:'32px', height:'32px'}} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >

        <MenuItem onClick={() => handleLogout('redirect')} key="logoutRedirect">
          <User />
        </MenuItem>
        <MenuItem key="divider">
          <Divider orientation="horizontal" variant="middle" flexItem />
        </MenuItem>

        <MenuItem onClick={() => handleLogout('popup')} key="logoutPopup">
          <PowerSettingsNew />
          <span style={{ paddingLeft: '8px' }}>Logout</span>
        </MenuItem>

      </Menu>
    </div>
  );
}
