
import { MenuOpen as ArrowLeft, Menu as ArrowRight, NotificationsActive, PowerSettingsNew, Settings, SummarizeOutlined, CloudSyncOutlined } from '@mui/icons-material';
import { useState } from 'react';
import logoImg from '../../assets/images/logo.png';
import { ClosedSideBar, Container, Content, OpenSideBar } from './styles';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { NavLink } from 'react-router-dom';
import RouteConstants from '../../constants/routes.enum';

import { darkTheme as theme } from '../../configs/theme';
import { useDelay } from '../../libs/Delay/Delay';

function SideBar() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [sideBar, setSideBar] = useState(false);
  const autoCloseInterval = 10000;

/*   let autoClose: string | number | NodeJS.Timeout | undefined = undefined;
  const funcAutoClose = ()=>{
    if (sideBar) {
      autoClose = setTimeout(() => {
        if (sideBar) {
          handleChangeSideBar();
        }
      }, autoCloseInterval);

    }
  }

  useEffect(() => {
    if (autoClose) {
      clearTimeout(autoClose);
    }
    funcAutoClose();
    
  }, [sideBar]); */
  useDelay(sideBar,handleChangeSideBar,autoCloseInterval);


  const handleLogOut = () => {
    const environment = process.env.NODE_ENV === "production" ? "PROD" : "DEV";
    instance.logoutPopup({
      mainWindowRedirectUri: environment === "PROD" ? process.env.REACT_APP_PROD_BASEPATH : process.env.REACT_APP_DEV_BASEPATH, // updated to env file base path '/',
    })
      .catch((error) => { console.log(error); });
  }
  function handleChangeSideBar() {
    setSideBar((prevState) => !prevState);
  }
  return (
    <Container>
      <Content>
        {!sideBar ? (
          <ClosedSideBar>
            <nav>
              <p>
                <button style={{ backgroundColor: 'transparent', borderStyle: 'none' }} onClick={handleChangeSideBar} title='Open'>

                  <ArrowRight />
                </button>
              </p>
              <img src={logoImg} alt='NOC' style={{ backgroundColor: theme.palette.common.white }} />

              {isAuthenticated &&
                <ul>
                  <NavLink target='_self' title='Submitted Reports' to={RouteConstants.HOME}>
                    <SummarizeOutlined />
                  </NavLink>
                  <NavLink target='_self' title='SAP Data' to={RouteConstants.HOME + RouteConstants.VENDORS}>
                    <CloudSyncOutlined />
                  </NavLink>
                  {/* <a href='/' title='Home'>
                    <Home />
                  </a>
                  <a href={'/vendors'} title='Vendors'>
                    <DeveloperBoard />
                  </a> */}
                </ul>
              }
            </nav>
            <div>
              {isAuthenticated &&
                <ul>
                  <a style={{ display: 'none' }} href='/' title='Notifications'>
                    <NotificationsActive />
                  </a>
                  <a style={{ display: 'none' }} href='/' title='Configuration'>
                    <Settings />
                  </a>
                  <NavLink target='_self' title='Log Out' onClick={() => { handleLogOut() }} to={'/*'}>
                    <PowerSettingsNew />
                  </NavLink>
                  {/* <a onClick={() => { handleLogOut() }} title='Log Out'>
                    <PowerSettingsNew />
                  </a> */}
                </ul>
              }
            </div>
          </ClosedSideBar>
        ) : (
          <OpenSideBar>
            <section>
              <nav>
                <p>
                  <button style={{ backgroundColor: 'transparent', borderStyle: 'none' }} onClick={handleChangeSideBar} title='Close'>
                    <ArrowLeft />
                  </button>
                </p>
                <div>
                  <img src={logoImg} alt='NOC' style={{ backgroundColor: theme.palette.common.white }} />
                  <p>North Oil<br />Company</p>
                </div>
                {isAuthenticated &&
                  <ul>
                    <NavLink target='_self' title='Submitted Reports' to={RouteConstants.HOME}>
                      <SummarizeOutlined />
                      <p><span>Submitted Reports</span></p>
                    </NavLink>
                    <NavLink target='_self' title='SAP Data' to={RouteConstants.HOME + RouteConstants.VENDORS}>
                      <CloudSyncOutlined />
                      <p>SAP Data</p>
                    </NavLink>
                    {/*  <a href='/' title='Home'>
                      <Home />
                      <p><span>Contractor Reports</span></p>
                    </a>
                    <a href='/vendors' title='Vendors'>
                      <DeveloperBoard />
                      <p>Vendors</p>
                    </a> */}

                  </ul>
                }
              </nav>
              <div>
                {isAuthenticated &&
                  <ul>
                    <a style={{ display: 'none' }} href='/' title='Notifications'>
                      <NotificationsActive />
                      <p>Notifications</p>
                    </a>
                    <a style={{ display: 'none' }} href='/' title='Configuration'>
                      <Settings />
                      <p>Configuration</p>
                    </a>
                    <NavLink target='_self' title='Log Out' onClick={() => { handleLogOut() }} to={'/*'}>
                      <PowerSettingsNew />
                      <p>Log Out</p>
                    </NavLink>
                    {/*  <a onClick={() => { handleLogOut() }} title='Log Out'>
                      <PowerSettingsNew />
                      <p>Log Out</p>
                    </a> */}

                  </ul>
                }
              </div>
            </section>
            <aside onClick={handleChangeSideBar} />
          </OpenSideBar>
        )}
      </Content>
    </Container >
  );
}

export default SideBar;
